<template>
  <div class="w-full h-full">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'DefaultLayout'
}
</script>
